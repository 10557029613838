var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showFlag
    ? _c("div", { staticClass: "photoAlbum" }, [
        _c("header", { staticClass: "title" }, [
          _vm._v(_vm._s(_vm.photoType) + "照片"),
        ]),
        _c("div", { staticClass: "imgBox" }, [
          _c("div", { staticClass: "innerBox" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: this.imgData.timeSer,
                    expression: "this.imgData.timeSer",
                  },
                ],
                staticStyle: { width: "59%", "margin-right": "1%" },
              },
              [
                _c("img", {
                  attrs: {
                    src: this.imgData.timeSer,
                    width: "100%",
                    height: "100px",
                  },
                }),
                _c("h3", { staticClass: "imgTitle" }, [
                  _vm._v(_vm._s(_vm.photoType) + "时序图"),
                ]),
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: this.imgData.car,
                    expression: "this.imgData.car",
                  },
                ],
                staticClass: "carPhoto",
              },
              [
                _c("img", {
                  attrs: {
                    src: this.imgData.car,
                    width: "100%",
                    height: "100px",
                  },
                }),
                _c("h3", { staticClass: "imgTitle" }, [_vm._v("车辆照片")]),
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: this.imgData.plateNum,
                    expression: "this.imgData.plateNum",
                  },
                ],
                staticClass: "platePhoto",
              },
              [
                _c("img", {
                  attrs: {
                    src: this.imgData.plateNum,
                    width: "100%",
                    height: "100px",
                  },
                }),
                _c("h3", { staticClass: "imgTitle" }, [_vm._v("车牌照片")]),
              ]
            ),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }