<template>
  <div class="swiper" @mouseenter="buttonFlag = true" @mouseleave="buttonFlag = false">
    <swiper :options="swiperOption" ref="mySwiper">
      <!-- slides -->
      <swiper-slide v-for="item in swiperData" :key="item.uploadTime">
        <div
          class="swiperImg"
          :style="{ background: 'url(' + item.picUrl + ') no-repeat 100% 100% / 100% 100%' }"
        >
          <span class="createdTime">{{ item.uploadTime }}</span>
        </div>
      </swiper-slide>
      <!-- Optional controls -->
      <div class="swiper-pagination" slot="pagination"></div>
      <div class="swiper-button-prev" slot="button-prev" v-show="buttonFlag"></div>
      <div class="swiper-button-next" slot="button-next" v-show="buttonFlag"></div>
      <div class="swiper-scrollbar" slot="scrollbar"></div>
    </swiper>
  </div>
</template>

<script>
import "swiper/dist/css/swiper.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";
export default {
  components: {
    swiper,
    swiperSlide,
  },
  props: ["berthId"],
  data() {
    let that = this;
    return {
      buttonFlag: false,
      swiperData: [],
      swiperOption: {
        notNextTick: true,
        // 设定初始化时slide的索引
        initialSlide: 0,
        // 设置轮播
        effect: "slide",
        // 滑动速度
        speed: 800,
        // 滑动方向
        direction: "horizontal",
        // 小手掌抓取滑动
        grabCursor: true,
        slidesPerView: 5,
        // 滑动之后回调函数
        on: {
          slidePrevTransitionEnd: function () {
            if (this.activeIndex === 0) {
              that.getSwiperData(
                {
                  direction: -1,
                  berthId: that.berthId,
                  startTime: that.swiperData[0].uploadTime,
                },
                -1
              );
            }
          },
          slideNextTransitionEnd: function () {
            if (this.activeIndex >= that.swiperData.length - 5) {
              that.getSwiperData(
                {
                  direction: 1,
                  berthId: that.berthId,
                  startTime: that.swiperData[that.swiperData.length - 1].uploadTime,
                },
                1
              );
            }
          },
        },
        // 左右点击
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    };
  },
  methods: {
    getSwiperData(data, type) {
      this.$axios
        .get("/acb/2.0/picture/featPicList", {
          data,
        })
        .then((res) => {
          if (res.state === 0) {
            if (res.value.list.length > 0) {
              if (type === -1) {
                this.swiperData = [...res.value.list, ...this.swiperData];
                this.$refs.mySwiper.swiper.slideTo(res.value.showIndex, 0);
              } else if (type === 1) {
                this.swiperData = [...this.swiperData, ...res.value.list];
              } else {
                this.swiperData = res.value.list;
                this.$refs.mySwiper.swiper.slideTo(res.value.showIndex - 1, 0);
              }
            }
          } else {
            this.$alert(res.desc);
          }
        });
    },
  },
};
</script>

<style>
.swiper {
  margin-top: 10px;
}
.swiper-slide {
  width: 300px; /*设为固定值*/
  width: auto; /*根据内容调整宽度*/
}
.swiperImg {
  width: 100%;
  height: 200px;
}
.createdTime {
  color: #000;
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 10px;
}
</style>
