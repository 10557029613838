<template>
  <div class="parkingDetails">
    <el-dialog
      title="停车记录详情"
      :visible.sync="dialogVisible"
      width="70%"
      @open="handleOpen"
    >
      <InfoTable :tableData="tableData" :dataValue="parkData" :num="3" />
      <photoAlbum photoType="入场" :imgData="entryPic" v-show="this.parkData.entryTime"/>
      <photoAlbum photoType="出场" :imgData="exitPic" v-show="this.parkData.exitTime"/>
      <swiper ref="swiper" :berthId="berthId" />
    </el-dialog>
  </div>
</template>

<script>
import photoAlbum from "./components/photoAlbum";
import swiper from "./components/swiper";
import InfoTable from "@/components/infoTable/infoTable";
export default {
  components: {
    InfoTable,
    photoAlbum,
    swiper
  },
  props: ["parkRecordId"],
  data() {
    return {
      berthId: "",
      entryPic: {
        timeSer: "",
        car: "",
        plateNum: ""
      },
      exitPic: {
        timeSer: "",
        car: "",
        plateNum: ""
      },
      parkData: {},
      loading: false,
      dialogVisible: false,
      tableData: [
        {
          name: "车牌号",
          key: "plateNumber"
        },
        {
          name: "停车场名称",
          key: "parkName"
        },
        {
          name: "泊位号",
          key: "berthCode"
        },
        {
          name: "入场时间",
          key: "strEntryTime"
        },
        {
          name: "出场时间",
          key: "strExitTime"
        },
        {
          name: "停车时长",
          key: "parkTime"
        },
        {
          name: "计费类型",
          key: "parkType",
          format: (dataObj, data, dataVal) => {
            let obj = {
              "1": "一类区",
              "2": "二类区",
              "3": "三类区"
            };
            return dataObj && obj[dataVal.parkType];
          }
        },
        {
          name: "应收金额",
          key: "shouldPay",
          format: (dataObj, data, dataVal) => {
            return dataVal.shouldPay / 100;
          }
        },
        {
          name: "入场设备类型",
          key: "entryDataSource",
          format: (dataObj, data, dataVal) => {
            let obj = {
              1: "视频设备A",
              2: "手持设备",
              3: "封闭停车场",
              4: "视频设备B",
              5: "异常列表",
              7: "人工校验",
              9: "巡检录入",
              10: "视频设备C",
              11: "视频设备D",
              12: "视频设备E",
              13: "视频设备F",
              14: "视频设备G",
              15: "视频设备H",
              16: "视频设备I",
              99: "三方平台",
              100: "违停球"
            };
            return dataVal && obj[dataVal.entryDataSource]
          }
        },
        {
          name: "出场设备类型",
          key: "exitDataSource",
          format: (dataObj, data, dataVal) => {
             let obj = {
              1: "视频设备A",
              2: "手持设备",
              3: "封闭停车场",
              4: "视频设备B",
              5: "异常列表",
              7: "人工校验",
              9: "巡检录入",
              10: "视频设备C",
              11: "视频设备D",
              12: "视频设备E",
              13: "视频设备F",
              14: "视频设备G",
              15: "视频设备H",
              16: "视频设备I",
              99: "三方平台",
              100: "违停球"
            };
            return dataVal && obj[dataVal.exitDataSource]
          }
        }
      ]
    };
  },
  methods: {
    getData() {
      this.$axios.get(`/acb/2.0/parkRecord/${this.parkRecordId}`).then(res => {
        this.parkData = res.value;
        this.$refs.swiper.swiperData = []
        this.getPic(res.value.entryOprNum, res.value.exitOprNum);
      });
    },
    getPic(entryOprNum, exitOprNum) {
      this.entryPic = {};
      this.exitPic = {};
      if (entryOprNum) {
        this.$axios
          .get("/acb/2.0/picture/getPictureListByOprNum/" + entryOprNum)
          .then(res => {
            if (res.state == 0) {
              res.value.forEach(e => {
                if (e.imageType === 1) {
                  this.$set(this.entryPic, "timeSer", e.picUrl);
                }
                if (e.imageType === 2) {
                  this.$set(this.entryPic, "car", e.picUrl);
                }
                if (e.imageType === 3) {
                  this.$set(this.entryPic, "plateNum", e.picUrl);
                }
              });
            } else {
              this.$alert(res.desc, this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine')
              });
            }
          });
      }
      if (exitOprNum) {
        this.$axios
          .get("/acb/2.0/picture/getPictureListByOprNum/" + exitOprNum)
          .then(res => {
            if (res.state == 0) {
              res.value.forEach(e => {
                if (e.imageType === 1) {
                  this.$set(this.exitPic, "timeSer", e.picUrl);
                }
                if (e.imageType === 2) {
                  this.$set(this.exitPic, "car", e.picUrl);
                }
                if (e.imageType === 3) {
                  this.$set(this.exitPic, "plateNum", e.picUrl);
                }
              });
              this.berthId = this.parkData.berthId;
              this.$refs.swiper.getSwiperData({
                direction: 0,
                berthId: this.berthId,
                startTime: this.parkData.strEntryTime
              });
            } else {
              this.$alert(res.desc, this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine')
              });
            }
          });
      }
    },
    async handleOpen() {
      this.getData();
    }
  }
};
</script>

<style lang="stylus" scoped>
>>>.el-dialog__header {
  padding: 10px 20px;
  background: #324057;

  span {
    color: #fff;
  }

  .el-dialog__headerbtn {
    top: 10px;
    right: 10px;

    .el-dialog__close {
      font-size: 24px;
    }
  }
}

>>>.el-dialog {
  margin-top: 5vh !important;
}

>>>.el-dialog__body {
  padding-top: 15px;
}

>>>.colName {
  text-align: center !important;
}

>>>.colValue {
  text-align: center !important;
}
</style>
