<template>
  <div class="customerDetails bgFFF paddingLR20 paddingT20">
    <titleBox title="客诉信息" :status="status">
      <el-form
        ref="form"
        :inline="true"
        label-position="right"
        :model="formInline"
        class="demo-form-inline"
        label-width="130px"
        :rules="rules"
      >
        <el-form-item label="客诉来源" prop="dataSource">
          <el-select v-model="formInline.dataSource" placeholder="请选择" style="width: 200px">
            <el-option
              v-for="item in customerDataSourceList"
              :key="item.value"
              :label="item.desc"
              :value="item.code"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('searchModule.Work_Order_Number')" prop="orderNo">
          <el-input v-model="formInline.orderNo" placeholder="请输入内容" style="width: 200px" maxlength="50"></el-input>
        </el-form-item>
        <el-form-item :label="$t('searchModule.Work_order_type')" prop="orderType">
          <el-select v-model="formInline.orderType" placeholder="请选择" style="width: 200px">
            <el-option
              v-for="item in orderTypeList"
              :key="item.value"
              :label="item.desc"
              :value="item.code"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="被反映单位" prop="questionCompany">
          <el-input v-model="formInline.questionCompany" maxlength="50" placeholder="请输入内容"></el-input>
        </el-form-item>
        <el-form-item label="承办单位" prop="executeCompany">
          <el-input v-model="formInline.executeCompany" maxlength="50" placeholder="请输入内容"></el-input>
        </el-form-item>
        <el-form-item label="联系人姓名" prop="name">
          <el-input v-model="formInline.name" placeholder="请输入内容" maxlength="20"></el-input>
        </el-form-item>
        <el-form-item label="联系电话" prop="phone">
          <el-input v-model="formInline.phone" placeholder="请输入内容" maxlength="20"></el-input>
        </el-form-item>
        <el-form-item label="是否重复投诉" prop="repeat">
          <el-select v-model="formInline.repeat" placeholder="请选择" style="width: 200px">
            <el-option label="是" :value="1"></el-option>
            <el-option label="否" :value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="平台派单时间"
          prop="platformTime"
          v-show="formInline.dataSource === 1 || formInline.dataSource === 2"
        >
          <el-date-picker
            @change="platformTimeChange"
            value-format="yyyy-MM-dd"
            style="width: 200px"
            v-model="formInline.platformTime"
            type="date"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="城管委派单时间" prop="urbanTime" v-show="formInline.dataSource === 2">
          <el-date-picker
            style="width: 200px"
            v-model="formInline.urbanTime"
            value-format="yyyy-MM-dd"
            type="date"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="提醒时间" prop="remindTime">
          <el-date-picker
            style="width: 200px"
            v-model="formInline.remindTime"
            :picker-options="pickerOptions"
            value-format="yyyy-MM-dd"
            type="date"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
        </el-form-item>
        <el-form-item
          label="截止时间"
          prop="cutoffTime"
          v-show="formInline.dataSource === 1 || formInline.dataSource === 2"
        >
          <el-date-picker
            value-format="yyyy-MM-dd"
            v-model="formInline.cutoffTime"
            type="date"
            style="width: 200px"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="投诉具体内容" prop="content">
          <el-input
            type="textarea"
            maxlength="500"
            v-model="formInline.content"
            placeholder="请输入内容"
            style="width: 500px"
          ></el-input>
        </el-form-item>
      </el-form>
    </titleBox>
    <titleBox title="停车信息" ref="parkMage">
      <div style="margin: 0 0 30px 20px">
        <el-button
          type="primary"
          @click="$refs.parkRecord.dialogVisible = true"
          :loading="loading"
        >选择停车记录</el-button>
        <el-button type="primary" @click="$refs.cale.dialogVisible = true;" :loading="loading">计费计算器</el-button>
      </div>
      <div class="tableWrapper">
        <el-table
          header-cell-class-name="header-call-style"
          border
          v-loading="loading"
          
          :data="tableData"
          style="width: 100%;"
        >
          <el-table-column type="index" :label="$t('list.index')" width="70" align="center"></el-table-column>
          <el-table-column
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
            align="center"
          ></el-table-column>
          <el-table-column label="操作" align="center" width="90">
            <template slot-scope="scope">
              <el-dropdown @command="handleCommand($event,scope.row)">
                <el-button type="text" size="small" style="padding: 0">操作<i class="el-icon-arrow-down" /></el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="a">查看详情</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </titleBox>
    <titleBox title="处理意见">
      <el-form
        ref="disForm"
        :inline="true"
        label-position="right"
        :model="opinion"
        class="demo-form-inline"
        label-width="130px"
        :rules="disRule"
      >
        <el-form-item label="市平台订单号">
          <el-input v-model="opinion.cityOrderNo" maxlength="50"></el-input>
        </el-form-item>
        <el-form-item
          label="问题一级分类"
          prop="classifyOne"
          :required="opinion.complaintStatus == '3'? true:false"
        >
          <el-select v-model="opinion.classifyOne" placeholder="请选择" style="width: 200px" @change="classifyOneChange">
            <el-option
              v-for="item in classifyOneList"
              :key="item.classifyId"
              :label="item.classifyOneName"
              :value="item.classifyId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="问题二级分类"
          prop="classifyTwo"
          :required="opinion.complaintStatus == '3'? true:false"
        >
          <el-select v-model="opinion.classifyTwo" placeholder="请选择" style="width: 200px">
            <el-option
              v-for="item in classifyTwoList"
              :key="item.classifyId"
              :label="item.classifyTwoName"
              :value="item.classifyId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('searchModule.Processing_results')" prop="complaintStatus">
          <el-select v-model="opinion.complaintStatus" placeholder="请选择" style="width: 200px">
            <el-option label="再次处理" value="2"></el-option>
            <el-option label="已办结" value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="再次提醒时间" prop="remindTime" v-show="this.opinion.complaintStatus == 2">
          <el-date-picker
            v-model="opinion.remindTime"
            value-format="yyyy-MM-dd"
            type="date"
            style="width: 200px"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="满意度" prop="evaluate" v-show="this.opinion.complaintStatus == 3">
          <el-select v-model="opinion.evaluate" placeholder="请选择" style="width: 200px">
            <el-option label="满意" value="0"></el-option>
            <el-option label="一般" value="1"></el-option>
            <el-option label="不满意" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('searchModule.Handling_method')" prop="content">
          <el-input
            maxlength="500"
            type="textarea"
            v-model="opinion.content"
            placeholder="请输入内容"
            style="width: 500px"
          ></el-input>
        </el-form-item>
      </el-form>
    </titleBox>
    <titleBox title="处理历史" v-show="formInline.complaintStatus !== 1">
      <div class="disHistory">
        <el-table :data="historyData">
          <el-table-column
            v-for="item in historyCols"
            :key="item.label"
            :property="item.prop"
            :label="item.label"
            align="center"
            :width="item.width"
          ></el-table-column>
        </el-table>
      </div>
    </titleBox>
    <parkRecord ref="parkRecord" :complaintId="opinion.complaintId" @searchData="getData" />
    <parkingDetails ref="parkingDetails" :parkRecordId="parkRecordId" :berthId="berthId" />
    <cale ref="cale" />
    <div style="margin: 0 auto; display: block; text-align: center">
      <el-button type="primary" @click="update" :loading="loading">提交</el-button>
      <!-- <el-button type="primary" @click="$router.goList('/complainManage')">取消</el-button> -->
      <el-button type="primary" @click="$router.go(-1)">取消</el-button>
    </div>
  </div>
</template>
<script>
import parkRecord from "./components/parkRecord";
import cale from "./components/cale";
import titleBox from "./components/title";
import parkingDetails from "./parkingDetails";
import { dateFormat } from "@/common/js/public";
export default {
  components: {
    titleBox,
    parkRecord,
    cale,
    parkingDetails
  },
  data() {
    // 已办结状态 满意度一二级分类为必填项； 再次处理状态  提醒时间为必填项
    let disRule = message => {
      return (rule, value, callback) => {
        let flag = true;
        if (
          message === "请输入再次提醒时间" &&
          this.opinion.complaintStatus == 2 &&
          !value
        ) {
          flag = false;
        } else if (
          message === "请选择满意度" &&
          this.opinion.complaintStatus == 3 &&
          !value
        ) {
          flag = false;
        } else if (
          message === "请选择一级分类" &&
          this.opinion.complaintStatus == '3' &&
          !value
        ) {
          flag = false;
        } else if (
          message === "请选择二级分类" &&
          this.opinion.complaintStatus == '3' &&
          !value
        ) {
          flag = false;
        }
        if (flag) {
          return callback();
        } else {
          return callback(new Error(message));
        }
      };
    };
    let rule = message => {
      return (rule, value, callback) => {
        let flag = true;
        if (message === "请输入提醒时间" && !value) {
          flag = false;
        } else if (
          message === "请输入截止时间" &&
          (this.formInline.dataSource === 1 ||
            this.formInline.dataSource === 2) &&
          !value
        ) {
          flag = false;
        } else if (
          message === "请输入城管委派单时间" &&
          this.formInline.dataSource === 2 &&
          !value
        ) {
          flag = false;
        } else if (
          message === "请输入平台派单时间" &&
          (this.formInline.dataSource === 1 ||
            this.formInline.dataSource === 2) &&
          !value
        ) {
          flag = false;
        }
        if (flag) {
          return callback();
        } else {
          return callback(new Error(message));
        }
      };
    };
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        }
      },
      parkRecordId: "",
      berthId: "",
      opinion: {
        complaintId: this.$route.query.complaintId,
        classifyOne: "",
        classifyTwo: "",
        complaintStatus: "2",
        content: "",
        remindTime: "",
        evaluate: ""
      },
      formInline: {
        complaintStatus: '',
        orderNo: "",
        dataSource: "",
        orderType: "",
        questionCompany: "",
        executeCompany: "",
        name: "",
        phone: "",
        repeat: "",
        platformTime: "",
        urbanTime: "",
        remindTime: "",
        cutoffTime: "",
        content: ""
      },
      loading: false,
      historyData: [],
      historyCols: [
        {
          prop: "createTime",
          label: this.$t("list.processing_time"),
          width: ""
        },
        {
          prop: "operationName",
          label: this.$t("list.Processing_personnel"),
          width: ""
        },
        {
          prop: "cityOrderNo",
          label: "市级平台订单号",
          width: ""
        },
        {
          prop: "strClassifyOne",
          label: "问题一级分类",
          width: ""
        },
        {
          prop: "strClassifyTwo",
          label: "问题二级分类",
          width: ""
        },
        {
          prop: "content",
          label: "处理方式",
          width: ""
        }
      ],
      tableData: [],
      tableCols: [
        {
          prop: "parkName",
          label: this.$t("list.park_name"),
          width: ""
        },
        {
          prop: "berthCode",
          label: this.$t("list.Berth_number"),
          width: ""
        },
        {
          prop: "parkType",
          label: this.$t("list.charge_type"),
          width: "",
          formatter: (row, column) => {
            let obj = {
              "1": "一类区",
              "2": "二类区",
              "3": "三类区"
            };
            return obj[row.parkType];
          }
        },
        {
          prop: "plateNumber",
          label: this.$t("list.plate_number"),
          width: ""
        },
        {
          prop: "strEntryTime",
          label: this.$t("list.entry_time"),
          width: ""
        },
        {
          prop: "strExitTime",
          label: this.$t("list.Appearance_time"),
          width: ""
        },
        {
          prop: "parkTime",
          label: this.$t("list.Parking_duration"),
          width: ""
        },
        {
          prop: "shouldPay",
          label: this.$t("list.amount_receivable"),
          width: "",
          formatter: (row, column) => {
            if (row.shouldPay) {
              return Number(row.shouldPay / 100).toFixed(2);
            } else {
              return "0.00";
            }
          }
        }
      ],
      disRule: {
        content: [
          {
            required: true,
            message: "请输入处理方式",
            trigger: "blur"
          }
        ],
        remindTime: [
          {
            validator: disRule("请输入再次提醒时间"),
            required: true,
            trigger: "blur"
          }
        ],
        classifyTwo: [{ validator: disRule('请选择二级分类') }],
        classifyOne: [{ validator: disRule('请选择一级分类') }],
        evaluate: [
          {
            validator: disRule("请选择满意度"),
            required: true,
            trigger: "blur"
          }
        ]
      },
      rules: {
        dataSource: [
          {
            required: true,
            message: "请选择客诉来源",
            trigger: "blur"
          }
        ],
        orderType: [
          {
            required: true,
            message: "请选择工单类型",
            trigger: "blur"
          }
        ],
        orderNo: [
          {
            required: true,
            message: "请输入工单编号",
            trigger: "blur"
          }
        ],
        // executeCompany: [
        //   {
        //     required: true,
        //     message: "请输入承办单位",
        //     trigger: "blur"
        //   }
        // ],
        name: [
          {
            required: true,
            message: "请输入联系人姓名",
            trigger: "blur"
          }
        ],
        phone: [
          {
            required: true,
            message: "请输入投诉人电话",
            trigger: "blur"
          }
        ],
        content: [
          {
            required: true,
            message: "请输入投诉具体内容",
            trigger: "blur"
          }
        ],
        remindTime: [
          { validator: rule("请输入提醒时间"), required: true, trigger: "blur" }
        ],
        urbanTime: [
          {
            validator: rule("请输入城管委派单时间"),
            required: true,
            trigger: "blur"
          }
        ],
        cutoffTime: [
          { validator: rule("请输入截止时间"), required: true, trigger: "blur" }
        ],
        platformTime: [
          {
            validator: rule("请输入平台派单时间"),
            required: true,
            trigger: "blur"
          }
        ],
        repeat: [
          {
            required: true,
            message: "请选择是否重复投诉",
            trigger: "blur"
          }
        ],
        questionCompany: [
          {
//            required: true,
            message: "请输入被反映单位",
            trigger: "blur"
          }
        ]
      },
      classifyOneList: [],
      classifyTwoList: [],
      orderTypeList: [],
      customerDataSourceList: []
    };
  },
  computed: {
    status: function () {
      let obj = {
        '1': '未处理',
        '2': '跟进中',
        '3': '已办结'
      }
      return obj[this.formInline.complaintStatus]
    }
  },
  methods: {
    platformTimeChange() {
      this.formInline.remindTime = dateFormat(new Date(new Date(this.formInline.platformTime).getTime() + 3 * 86400000), 'yyyy-MM-dd')
    },
    classifyOneChange() {
      this.getClassify(2, this.opinion.classifyOne)
    },
    getClassify(type, classifyOne) {
      this.$axios
        .get("/acb/2.0/customerClassify/list", {
          data: {
            type,
            pageNum: 0,
            pageSize: 0,
            classifyOne
          }
        })
        .then(res => {
          if (type === 1) {
            this.classifyOneList = res.value.list;
          } else {
            this.opinion.classifyTwo = ''
            this.classifyTwoList = res.value.list;
          }
        });
    },
    update() {
      let flagForm = false;
      let flagDisForm = false;
      if (this.formInline.cutoffTime && this.formInline.remindTime && (new Date(this.formInline.cutoffTime) < new Date(this.formInline.remindTime))) {
        this.$alert('截止时间不能小于提醒时间')
        return
      }
      if (this.formInline.platformTime && this.formInline.cutoffTime && (new Date(this.formInline.cutoffTime) < new Date(this.formInline.platformTime))) {
        this.$alert('截止时间不能小于平台派单时间')
        return
      }
      if (this.formInline.urbanTime && this.formInline.cutoffTime && (new Date(this.formInline.cutoffTime) < new Date(this.formInline.urbanTime))) {
        this.$alert('截止时间不能小于城管委派单时间')
        return
      }
      if (this.formInline.urbanTime && this.formInline.remindTime && (new Date(this.formInline.remindTime) < new Date(this.formInline.urbanTime))) {
        this.$alert('提醒时间不能小于城管委派单时间')
        return
      }
      if (this.formInline.platformTime && this.formInline.remindTime && (new Date(this.formInline.remindTime) < new Date(this.formInline.platformTime))) {
        this.$alert('提醒时间不能小于平台派单时间')
        return
      }
      if (this.opinion.remindTime && (new Date(this.formInline.cutoffTime) < new Date(this.opinion.remindTime))) {
        this.$alert('截止时间不能小于再次提醒时间')
        return
      }
      this.$refs.form.validate(v => {
        flagForm = v
        this.$refs.disForm.validate(f => {
          flagDisForm = f
        });
      });
      if (flagForm && flagDisForm) {
        this.$axios
          .post("/acb/2.0/customerComplaint/update", {
            data: {
              ...this.formInline
            }
          })
          .then(res => {
            this.commit();
          });
      }
    },
    async commit() {
      this.$axios
        .post("/acb/2.0/customerComplaint/handle", {
          data: {
            ...this.opinion,
            remindTime: this.opinion.remindTime
          }
        })
        .then(res => {
          if (res.state === 0) {
            // this.$router.goList("/complainManage");
            this.$router.go(-1);
          } else {
            this.$alert(res.desc);
          }
        });
    },
    getAddOption() {
      this.$axios.get("/acb/2.0/customerComplaint/getAddOption").then(res => {
        this.orderTypeList = res.value.customerOrderType;
        this.customerDataSourceList = res.value.customerDataSource;
      });
    },
    getData(updata) {
      this.$axios
        .get(
          `acb/2.0/customerComplaint/detail/${this.$route.query.complaintId}`
        )
        .then(res => {
          if (res.state == 0) {
            if (updata) {
              this.tableData = res.value.listParkRecord;
            } else {
              this.tableData = res.value.listParkRecord;
              this.historyData = res.value.listExecute;
              this.formInline = res.value;
              // this.formInline.platformTime = res.value.platformTime && new Date(`${res.value.platformTime} 00:00:00`);
              // this.formInline.urbanTime = res.value.urbanTime && new Date(`${res.value.urbanTime} 00:00:00`);
              // this.formInline.remindTime = res.value.remindTime && new Date(`${res.value.remindTime} 00:00:00`);
              // this.formInline.cutoffTime = res.value.cutoffTime && new Date(`${res.value.cutoffTime} 00:00:00`);
            }
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine')
            });
          }
        });
    },
    handleCommand(cmd, data) {
      if (cmd === "a") {
        this.$refs.parkingDetails.dialogVisible = true;
        this.parkRecordId = data.parkRecordId;
        this.berthId = data.berthId;
      }
      if (cmd === "b") {
        this.$router.push({
          path: "/disposeComplain",
          query: {
            parkId: data.parkId
          }
        });
      }
    }
  },
  created() {
    this.getAddOption();
    this.getData();
    this.getClassify(1);
  },
  mounted() {
    this.$refs.parkMage.onClick = false;
  }
};
</script>
<style lang="stylus" scoped>
>>>.colName {
  text-align: center !important;
}

>>>.colValue {
  text-align: center !important;
}
</style>
