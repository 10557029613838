<template>
  <div class="parkRecrod">
    <el-dialog title="停车记录" :visible.sync="dialogVisible" width="70%">
      <el-form
        :inline="true"
        ref="form"
        label-position="right"
        :model="formInline"
        class="demo-form-inline"
      >
        <el-form-item :label="$t('searchModule.park_name')">
          <my-component
            ref="parkInput"
            :areaIds="''"
            :operationId="''"
            @valueChange="completeValue"
            slaveRelations="0,1"
          ></my-component>
        </el-form-item>
        <el-form-item :label="$t('searchModule.plate_number')" prop="plateNumber" ref="plateNumber">
          <el-autocomplete
            ref="plateNumber"
            size="11"
            valueKey="plateNumber"
            class="inline-input"
            v-model="formInline.plateNumber"
            :fetch-suggestions="querySearchAsync"
            placeholder="车牌号"
            :trigger-on-focus="false"
            @select="handleSelect"
          ></el-autocomplete>
        </el-form-item>
        <el-form-item :label="$t('searchModule.Entry_and_exit_types')">
          <el-select v-model.trim="formInline.entryOrExit" filterable size="8">
            <el-option
              :label="item.desc"
              :value="item.code"
              :key="item.code"
              v-for="item in ExitTypeList"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('searchModule.time_slot')">
          <el-date-picker v-model="date1" type="datetime" placeholder="选择日期"></el-date-picker>至
          <el-date-picker
            v-model="date2"
            type="datetime"
            placeholder="选择日期"
            default-time="23:59:59"
          ></el-date-picker>
        </el-form-item>
        <el-button
          type="primary"
          icon="el-icon-search"
          @click="
            pageNum = 1;
            searParkRecordList();
          "
          :loading="loading"
          >{{ $t('button.search') }}</el-button
        >
        <el-button type="primary" icon="el-icon-delete" @click="resetForm()">{{ $t('button.reset') }}</el-button>
      </el-form>
      <el-table :data="tableData" @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column
          v-for="item in tableCols"
          :key="item.label"
          :property="item.prop"
          :formatter="item.formatter"
          :label="item.label"
          :width="item.width"
        ></el-table-column>
      </el-table>
      <!--分页器-->
      <div class="pagerWrapper">
        <div class="block">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="pageNum"
            :page-size="pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total"
          ></el-pagination>
        </div>
      </div>
      <div style="text-align: center; margin-top: 20px">
        <el-button @click="save" :loading="loading" type="primary">确定</el-button>
        <el-button @click="close('form')" :loading="loading" type="primary">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getDt, dateFormat, setIndex } from "@/common/js/public.js";
import myComponent from "@/components/autocomplete/myautoComponent";
export default {
  name: "parkRecordTotal",
  props: ["complaintId"],
  components: {
    myComponent,
  },
  data() {
    let date1 = new Date();
    date1.setHours(0);
    date1.setMinutes(0);
    date1.setSeconds(0);
    date1.setTime(date1.getTime() - 3600 * 1000 * 24 * 30);
    let startTime = date1;
    let date = new Date();
    date.setHours(23);
    date.setMinutes(59);
    date.setSeconds(59);
    let endTime = date;
    return {
      parkRecordIds: "",
      flag: false,
      plateNumber: "",
      dialogVisible: false,
      total: 0,
      date1: startTime,
      date2: endTime,
      pageSize: 15,
      pageNum: 1,
      loading: false,
      ExitTypeList: [],
      tableCols: [
        {
          prop: "index",
          label: "序号",
          width: "70",
        },
        {
          prop: "parkName",
          label: this.$t("list.park_name"),
          width: "",
        },
        {
          prop: "berthCode",
          label: this.$t("list.Berth_number"),
          width: "",
        },
        {
          prop: "plateNumber",
          label: this.$t("list.plate_number"),
          width: "",
          formatter: (row, column) => {
            if (row.plateNumber.indexOf("无") != -1) {
              return "无牌车";
            } else {
              return row.plateNumber;
            }
          },
        },
        {
          prop: "strEntryTime",
          label: this.$t("list.entry_time"),
          width: "",
        },
        {
          prop: "strExitTime",
          label: this.$t("list.leaving_time"),
          width: "",
        },
        {
          prop: "parkTime",
          label: this.$t("list.Parking_duration"),
          width: "",
        },
        {
          prop: "shouldPay",
          label: this.$t("list.amount_receivable"),
          width: "100",
          formatter: (row, column) => {
            if (row.shouldPay) {
              return Number(row.shouldPay / 100).toFixed(2);
            } else {
              return "0.00";
            }
          },
        },
      ],
      tableData: [],
      formInline: {
        areaId: "",
        streetId: "",
        operationId: "",
        parkId: "",
        plateNumber: "",
        entryOrExit: 1,
        startTime: dateFormat(startTime, "yyyy-MM-dd HH:mm:ss"),
        endTime: dateFormat(endTime, "yyyy-MM-dd HH:mm:ss"),
        mobile: "",
      },
    };
  },
  methods: {
    save() {
      this.$axios
        .post("/acb/2.0/customerComplaint/updateParkRecordIds", {
          data: {
            parkRecordIds: this.parkRecordIds,
            complaintId: this.complaintId,
          },
        })
        .then((res) => {
          if (res.state === 0) {
            this.resetForm();
            this.tableData = [];
            this.$emit("searchData", "updata");
            this.dialogVisible = false;
          } else {
            this.$alert(res.desc);
          }
        });
    },
    close(form) {
      this.resetForm();
      this.tableData = [];
      this.dialogVisible = false;
    },
    handleSelectionChange(val) {
      let arr = [];
      val.forEach((e) => {
        arr.push(e.parkRecordId);
      });
      this.parkRecordIds = arr.join(",");
    },
    resetForm() {
      this.$refs["form"].clearValidate();
      let date1 = new Date();
      date1.setHours(0);
      date1.setMinutes(0);
      date1.setSeconds(0);
      date1.setTime(date1.getTime() - 3600 * 1000 * 24 * 30);
      let startTime = date1;
      let date = new Date();
      date.setHours(23);
      date.setMinutes(59);
      date.setSeconds(59);
      let endTime = date;
      this.formInline = {
        areaId: "",
        streetId: "",
        operationId: "",
        parkId: "",
        parkType: "-1",
        plateNumber: "",
        entryOrExit: 1,
        startTime: dateFormat(startTime, "yyyy-MM-dd HH:mm:ss"),
        endTime: dateFormat(endTime, "yyyy-MM-dd HH:mm:ss"),
        mobile: "",
      };
      this.date1 = startTime;
      this.date2 = endTime;
      this.formInline.parkId = "";
      this.formInline.parkName = "";
      this.$refs.parkInput.setValue();
      this.total = 0;
      this.pageNum = 1;
    },
    completeValue(item) {
      if (item) {
        this.formInline.parkId = item.parkId;
        this.formInline.parkName = item.parkName;
      } else {
        this.formInline.parkId = "";
        this.formInline.parkName = "";
      }
    },
    querySearchAsync(queryString, cb) {
      this.loading = false;
      if (queryString.length < 3) {
        cb([]);
        return;
      }
      let opt = {
        method: "get",
        url: "/acb/2.0/parkRecord/plateNumber/list",
        data: {
          part: queryString,
          size: 100,
        },
        success: (res) => {
          if (res.state == 0) {
            res.value.forEach((v) => {
              v.value = v.plateNumber;
            });
            cb(res.value);
          }
        },
      };
      getDt(opt);
    },
    handleSelect(item) {
      this.loading = false;
      this.formInline.plateNumber = item.plateNumber;
      this.formInline.carId = item.carId;
      this.pageNum = 1;
      this.searParkRecordList();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      if (!this.flag) {
        this.searParkRecordList();
      }
    },
    getExitType() {
      let opt = {
        url: "/acb/2.0/parkRecord/entryExitType/list",
        method: "get",
        data: {},
        success: (res) => {
          if (res.state == 0) {
            this.ExitTypeList = res.value;
          } else {
            this.$alert("获取出入场类型失败", this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      getDt(opt);
    },
    showLog() {
      if (this.date1 && this.date2) {
        let startTime = dateFormat(this.date1, "yyyy-MM-dd HH:mm:ss");
        let endTime = dateFormat(this.date2, "yyyy-MM-dd HH:mm:ss");
        this.formInline.startTime = startTime || this.date1;
        this.formInline.endTime = endTime || this.date2;
        var time = new Date(this.formInline.endTime) - new Date(this.formInline.startTime);
        time = time / (24 * 60 * 60 * 1000);
        if (this.formInline.plateNumber == "" && time > 31) {
          this.$alert("请缩小搜索范围，您可输入车牌号或搜索时间范围不超过31天");
          return false;
        }
        if (this.formInline.startTime > this.formInline.endTime) {
          this.$alert("开始时间不能大于结束时间");
          return false;
        } else {
          return true;
        }
      } else {
        this.formInline.startTime = "";
        this.formInline.endTime = "";
        this.$alert("时间段不能为空", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return false;
      }
    },
    searParkRecordListFun() {
      let opt = {
        method: "get",
        url: "/acb/2.0/parkRecord/list",
        data: {
          page: this.pageNum,
          pageSize: this.pageSize,
          areaId:
            this.formInline.streetId != "" ? this.formInline.streetId : this.formInline.areaId,
          parkId: this.formInline.parkId,
          berthId: this.formInline.berthId,
          operationId: this.formInline.operationId,
          parkType: this.formInline.parkType,
          entryOrExit: this.formInline.entryOrExit,
          startTime: this.formInline.startTime,
          endTime: this.formInline.endTime,
          plateNumber: this.formInline.plateNumber,
          mobile: this.formInline.mobile,
          carId: this.formInline.carId,
        },
        success: (res) => {
          this.loading = false;
          if (res.state == 0) {
            this.tableData = setIndex(this.pageNum, res.value.list);
            this.total = res.value.total * 1 || 0;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      getDt(opt);
    },
    // 搜索
    searParkRecordList() {
      if (this.formInline.plateNumber.length < 3) {
        this.formInline.plateNumber = "";
        this.formInline.carId = "";
        this.loading = false;
      }
      let flag = this.showLog();
      this.$refs["form"].validate((v) => {
        if (v) {
          if (flag) {
            this.loading = true;
            this.flag = false;
            this.$refs.parkInput.setShowVal(this.formInline.parkName);
            this.searParkRecordListFun();
          }
        }
      });
    },
  },
  created() {
    this.getExitType();
  },
};
</script>
<style lang="stylus" scoped>
.pagerWrapper {
  text-align: right;
  margin-top: 28px;
  font-size: 12px;
}

.searchWapper {
}

>>>.el-dialog__header {
  padding: 10px 20px;
  background: #324057;

  span {
    color: #fff;
  }

  .el-dialog__headerbtn {
    top: 10px;
    right: 10px;

    .el-dialog__close {
      font-size: 24px;
    }
  }
}

>>>.el-dialog {
  margin-top: 5vh !important;
}

>>>.el-dialog__body {
  padding-top: 15px;
}
</style>
